<template>
  <div class="forgot-password-page">
    <v-row>
      <v-col md="6">
        <ForgotPasswordForm />
      </v-col>
      <v-col md="6" class="bg">
        <div class="forgot-password-page__content">
          <h1 class="forgot-password-page__content--title">
            Bienvenue sur votre <br />espace privé
          </h1>
          <p class="forgot-password-page__content--subtitle">
            Un outil de conception de devis fait pour vous
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ForgotPasswordPage",
  components: {
    ForgotPasswordForm: () =>
      import("~cp/Auth/ForgotPasswordForm/ForgotPasswordForm"),
  },
};
</script>

<style src="../AuthPage.scss" lang="scss" scoped></style>
